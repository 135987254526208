import React from 'react'
import FeatureCard from '../../Components/FeatureCard/FeatureCard'
import Tittle from '../../Components/Tittle/Tittle'
import { faSmile, faSms, faChild, faBattery, faNetworkWired, faUserFriends } from '@fortawesome/free-solid-svg-icons'
function Features() {
  return (
    <div className='Features'>
      <div className='p-10'>
        <div>
          <Tittle name="Features" />
        </div>
      <div className="mt-6 space-y-12 lg:grid lg:grid-cols-3 lg:gap-x-6 lg:space-y-0">
        <FeatureCard icon={faSmile} tittle="EASY TO USE" text="The service is so easy to use. School do not need any additional technical know how to operate" />
        <FeatureCard icon={faSms} tittle="SERVICE USES SMS" text="This service is a SMS based service (Text Messaging), This allows a wide range of users, and end users don’t need to use the Internet." />
        <FeatureCard icon={faChild} tittle="ONE CHILD, 4 WAYS OF VERIFICATION" text="You can verify pick up via Face Recognition, Finger Print, Smart Card, Pass code or any combination " />
        <FeatureCard icon={faBattery} tittle="AMAZING BATTERY LIFE" text="Device can run on battery for 3 days when fully charged." />
        <FeatureCard icon={faNetworkWired} tittle="4G NETWORK" text="The service works on the 4G network ensuring that the service is fast and reliable" />
        <FeatureCard icon={faUserFriends} tittle="FREE ATTENDANCE FOR STAFF" text="Take your Staff attendance at no extra cost" />
       </div>
      </div>
    </div>
  )
}

export default Features