import React from 'react';
import { Link } from 'react-router-dom';

const Pricing = () => {
  const pricingPlans = [
    {
      name: 'Basic',
      price: '$19/month',
      features: ['Single Parent Alert', 'Verification via Smart Card', 'School Records', 'Free Staff Attendance'
      , 'Verification via Fingerprint', 'Verification via Facial Recognition'],
      sms: 'One SMS',
    },
    {
      name: 'Premium',
      price: '$49/month',
      features: ['Double Parent Alert', 'Whats App Enabled', 'Verification via Smart Card', 'School Records',         
      'Free Staff Attendance'
      , 'Verification via Fingerprint', 'Verification via Facial Recognition'],
      sms: 'Two SMS',
    },
    {
      name: 'Dedicated Services',
      price: '',
      features: ['Double Parent Alert', 'Customizable', 'Inject School Notifications', 'Monitor and Manage Exits and School Returns'
      , 'Verification via Fingerprint', 'Verification via Facial Recognition'],
      sms: 'Call for more info',
    },
  ];

  return (
    <div className="bg-gray-100 py-16">
      <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-base text-indigo-600 font-semibold tracking-wide uppercase">Pricing</h2>
          <p className="mt-2 text-3xl font-extrabold text-gray-900 sm:text-4xl lg:text-5xl">
            Affordable Pricing Plans
          </p>
          <p className="mt-2 max-w-4xl mx-auto text-xl text-gray-500">
            Choose the plan that works best for your needs.
          </p>
        </div>
        <div className="mt-16 grid gap-10 md:grid-cols-3 md:gap-y-0 md:gap-x-8">
          {pricingPlans.map((plan, index) => (
            <div
              key={index}
              className="p-8 bg-white border border-gray-200 rounded-lg shadow-lg"
            >
              <h3 className="text-xl font-semibold text-gray-900">{plan.name}</h3>
              <div className="mt-4">
                {/* <div className="text-3xl font-extrabold text-indigo-600">{plan.price}</div> */}
                <div className="text-3xl font-semibold text-center text-indigo-600">{plan.sms}</div>
                <p className="mt-2 text-base text-gray-500">per month</p>
              </div>
              <ul className="mt-6 space-y-4">
                {plan.features.map((feature, index) => (
                  <li key={index} className="flex items-start">
                    <div className="flex-shrink-0">
                      <svg
                        className="h-6 w-6 text-green-500"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M5 13l4 4L19 7"
                        />
                      </svg>
                    </div>
                    <p className="ml-3 text-base text-gray-700">{feature}</p>
                  </li>
                ))}
              </ul>
              <div className="mt-6">
                <Link className="block text-center w-full bg-indigo-600 border border-transparent rounded-md py-2 px-4 text-white font-semibold hover:bg-indigo-700">Get started</Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Pricing;
