import React from 'react'

function BtnPrimary(props) {
  return (
    <div className='BtnPrimary'>
      <button className='p-8 pb-6 pt-6 rounded-lg font-semibold text-white bg-indigo-600'>{props.BtnName}</button>
    </div>
  )
}

export default BtnPrimary