import React from 'react';
import Tittle from '../../Components/Tittle/Tittle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMessage, faPhone } from '@fortawesome/free-solid-svg-icons';
import './ContactSection.scss'
import Nigeria from '../../Img/nigeria.png'
import America from '../../Img/united-states.png'

const CallButton = () => {
  const phoneNumber = '07065 696954';
  // const whatsappNumber = '+1 708-794-6132'; 
  const email = 'info@kiddoalert.gmail.com';

  const handleCallClick = () => {
    window.location.href = `tel:${phoneNumber}`;
  };

  const handleWhatsAppClick = () => {
    const whatsappLink = 'https://wa.me/qr/JI3Q2INFHOR4P1';
    window.open(whatsappLink, '_blank');
  };
  const handleEmailClick = () => {
    window.location.href = `mailto:${email}`;
  };


  return (
    <div className='ContactSection'>
      <div className='p-10'>
        <Tittle name="Make an Enquiry" />
      </div>

      <div className='m-5 p-10 lg:grid lg:grid-cols-2 lg:gap-x-6 lg:space-y-0'>
        <div className='address'>
          <h5 className='text-xl font-bold mb-5'>Kano, Nigeria</h5>
          <p className='mb-3'><span className='text-lg font-semibold'>Address : </span>Prime Hub along Zoo road</p>
          <p className='mb-3'><span className='text-lg font-semibold'>Email : </span>info@kiddoalert.gmail.com</p>
          {/* <p className='mb-3'><span className='text-lg font-semibold'>Phone Numbers : </span>+1 708-794-6132 
          <span><img src={America} alt='America' /></span>, +234 7065 696954 <span><img src={Nigeria} alt='Nigeria' /></span></p> */}
          <p className='flex mb-5'><img src={America} alt='America' /> <small className='ml-3 text-xl'>+1 708-794-6132</small></p>
          <p className='flex'><img src={Nigeria} alt='Nigeria' /> <small className='ml-3 text-xl'>+234 7065 696954</small></p>
          <div className='mt-5'>
            <button className='mr-5 p-5 bg-indigo-500 rounded-2xl text-white hover:bg-indigo-700' onClick={handleCallClick}>
              Give us a call now
              <FontAwesomeIcon className='ml-3' icon={faPhone} />
              </button>
            <button className='m-5 p-5 bg-indigo-500 rounded-2xl text-white hover:bg-indigo-700' onClick={handleWhatsAppClick}>
              Chat with us on WhatsApp
              <FontAwesomeIcon className='ml-3' icon={faMessage} />
              </button>
          </div>
        </div>
        <div className='form text-center p-5'>
          <h2 className='text-2xl font-medium mt-5 mb-5'>Send a Message</h2>
          <form>
            <input placeholder='Your Name' />
            <input placeholder='Your Email' />
            <textarea placeholder='Your Message'></textarea>
            <button className='text-start mt-5 p-5 bg-indigo-500 rounded-lg text-white' onClick={handleEmailClick}>Submit</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CallButton;
