import React from 'react'
import HomeHeader from '../../Sections/HomeHeader/HomeHeader'
import Features from '../../Sections/Features/Features'
import TestimonialSlider from '../../Sections/Testimonies/Testimonies'
import Pricing from '../../Sections/PricingPages/PricingPages'
import ContactSection from '../../Sections/ContactSection/ContactSection'
import Footer from '../../Sections/Footer/Footer'

function Home() {
  return (
    <div>
      <HomeHeader />
      <Features />
      <TestimonialSlider />
      <Pricing />
      <ContactSection />
      <Footer />
    </div>
  )
}

export default Home