import React from 'react'

function Tittle(props) {
  return (
    <div className='Tittle'>
      <h2 className='text-4xl font-semibold text-gray-600'>{props.name}</h2>
    </div>
  )
}

export default Tittle