import React from 'react';
import { Link } from 'react-router-dom';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faCalculator, faLink, faPhone } from '@fortawesome/free-solid-svg-icons';

const Footer = () => {
  return (
    <footer className="bg-gray-900 text-white py-12">
      <div className="container mx-auto grid grid-cols-1 md:grid-cols-3 gap-8">
        {/* Left Section 1 */}
        <div className="md:col-span-1 p-8">
          <h2 className="text-2xl font-bold mb-4">About us</h2>
          <ul className="space-y-2">            
            <li>
            <Link className="text-gray-400 hover:text-white">About Kiddo Alert</Link>
            </li>
            <li>
            <Link className="text-gray-400 hover:text-white">Features</Link>
            </li>
            <li>
            <Link className="text-gray-400 hover:text-white">Blog</Link>
            </li>
            <li>
            <Link className="text-gray-400 hover:text-white">Contact</Link>
            </li>
 
          </ul>
        </div>


        {/* Left Section 2 */}
        <div className="md:col-span-1 p-8">
          <h2 className="text-2xl font-bold mb-4">For Student</h2>
          <ul className="space-y-2">
          <li>
            <Link className="text-gray-400 hover:text-white">SMS</Link>
            </li>
            <li>
            <Link className="text-gray-400 hover:text-white">Whats App Enabled</Link>
            </li>
            <li>
            <Link className="text-gray-400 hover:text-white">Verification via Fingerprint</Link>
            </li>
            <li>
            <Link className="text-gray-400 hover:text-white">Customizable</Link>
            </li>
            <li>
            <Link className="text-gray-400 hover:text-white">Verification via Facial Recognition</Link>
            </li>
            <li>
            <Link className="text-gray-400 hover:text-white">Double Parent Alert</Link>
            </li>
          </ul>
        </div>


        {/* Left Section 3 */}
        <div className="md:col-span-1 p-8">
          <h2 className="text-2xl font-bold mb-4">For Other</h2>
          <ul className="space-y-2">
          <li>
            <Link className="text-gray-400 hover:text-white">Free Staff Attendance</Link>
            </li>
            <li>
            <Link className="text-gray-400 hover:text-white">School Records</Link>
            </li>
            <li>
            <Link className="text-gray-400 hover:text-white">Monitor and Manage Exits and School Returns</Link>
            </li>
            <li>
            <Link className="text-gray-400 hover:text-white">Verification via Fingerprint</Link>
            </li>
          </ul>
        </div>

        {/* Right Section 3 */}
      
      </div>
      <div className="p-5 text-center">
          <h2 className="text-sm font-bold mb-4">Copyright 2023 © Built by <Link className='underline'>NYM Technologies Limited</Link></h2>
          {/* <div className="flex space-x-4">
            <Link className="text-gray-400 hover:text-white">
              <FontAwesomeIcon icon={faLink} />
            </Link>
            <Link className="text-gray-400 hover:text-white">
              <FontAwesomeIcon icon={faCalculator} />
            </Link>
            <Link className="text-gray-400 hover:text-white">
              <FontAwesomeIcon icon={faPhone} />
            </Link>
          </div> */}
        </div>
    </footer>
  );
};

export default Footer;
