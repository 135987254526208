// import { faSmile } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

function FeatureCard(props) {
  return (
    <div className='FeatureCard'>
      <div className='p-5 m-5 shadow-lg rounded-xl bg-gray-300'>
        <div className='text-center p-5'>
          <FontAwesomeIcon icon={props.icon} className='text-4xl' />
        </div>
        <div>
          <h2 className='text-center font-semibold'>{props.tittle}</h2>
          <p className='p-5'>{props.text}</p>
        </div>
      </div>
    </div>
  )
}

export default FeatureCard