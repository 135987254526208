import React, { useState } from 'react';
import './Testimonies.scss'
import UserOne from '../../Img/user5.jpg'
import UserTwo from '../../Img/user6.jpg'
import UserThree from '../../Img/user7.jpg'
import UserFour from '../../Img/user8.jpg'
import UserFive from '../../Img/user9.jpg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart, faComment } from '@fortawesome/free-solid-svg-icons';
import { Fade } from 'react-awesome-reveal';


const Testimonial = ({ image, quote, author, company }) => {
  return (
    <Fade direction='left' cascade>
    <div className="bg-white p-6 rounded-lg shadow-md">
      <div className="flex items-center justify-center">
        <img src={image} alt={author} className="w-16 h-16 rounded-full mr-4" />
        <div>
          <p className="text-gray-800 font-bold text-lg">{author}</p>
          <p className="text-gray-500">{company}</p>
        </div>
      </div>
      <p className="mt-4 text-gray-600 text-lg text-center">"{quote}"</p>
      <div className='flex'>
        <p className='ml-0 mr-2'>
        <FontAwesomeIcon icon={faHeart} className="text-red-500 pr-1 hover:text-black" />
        <small>15M</small>
        </p>
        <p>
        <FontAwesomeIcon icon={faComment} className="text-blue-500 pr-1" />
        <small>10K</small>
        </p>
      </div>
    </div>
    </Fade>
  );
};

const TestimonialSlider = () => {
  const testimonialsData = [
    {
      image: UserOne,
      quote: 'The drop-off notification feature has been a game-changer for our family. It eases my mind knowing my child is safely at school, and I love the convenience of receiving notifications in real-time.',
      author: 'David M',
      company: 'Parent',
    },
    {
      image: UserTwo,
      quote: 'As a working parent, these notifications are a lifesaver. I can time my morning routine better and have peace of mind, knowing my child has arrived at school.',
      author: 'Rebecca L',
      company: 'Parent',
    },
    {
      image: UserThree,
      quote: 'The school drop-off notifications have made communication between parents and the school seamless. Its great to know that safety and timely information are top priorities.',
      author: 'Michael R',
      company: 'Parent',
    },
    {
      image: UserFour,
      quote: "Implementing this feature has enhanced our school's safety protocols and improved parent-school relationships. It's a win-win for everyone involved.",
      author: 'Sarah H ',
      company: 'School Principal',
    },
    {
      image: UserFive,
      quote: "From a teacher's perspective, these notifications help create a smoother morning routine in the classroom. It's all about keeping everyone informed and ensuring a safe school environment.",
      author: 'Alex P',
      company: 'Teacher',
    },
  ];

  const [currentSlide, setCurrentSlide] = useState(0);

  const prevSlide = () => {
    setCurrentSlide((prev) => (prev === 0 ? testimonialsData.length - 1 : prev - 1));
  };

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev === testimonialsData.length - 1 ? 0 : prev + 1));
  };

  return (
    <div className="Testimonial bg-gray-100 py-12">
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {testimonialsData.map((testimonial, index) => (
            <div
              key={index}
              className={`${
                index === currentSlide ? 'scale-100 opacity-100' : 'scale-90 opacity-70'
              } transform transition-opacity duration-300 ease-in-out hover:scale-105 hover:opacity-100`}
            >
              <Testimonial {...testimonial} />
            </div>
          ))}
        </div>
        <div className="flex justify-center mt-6">
          <button
            onClick={prevSlide}
            className="text-3xl text-white hover:text-gray-300 focus:outline-none mr-4"
          >
            &#8249;
          </button>
          <button
            onClick={nextSlide}
            className="text-3xl text-white hover:text-gray-300 focus:outline-none"
          >
            &#8250;
          </button>
        </div>
      </div>
    </div>
  );
};

export default TestimonialSlider;
