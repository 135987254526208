import React from 'react'
import Nav from'../../Components/Nav/Nav'
import './HomeHeader.scss'
import BtnPrimary from '../../Components/BtnPrimary/BtnPrimary'

function HomeHeader() {
  return (
    <div className="bg-white HomeHeader">
    <Nav />
    <div className="lg:px-8">      
      <div className="pl-20 max-w-xl py-32 sm:py-48 lg:py-56 ">
        <div className="">
          <h1 className="text-xl font-bold tracking-wide text-white shadow-2 sm:text-4xl">
          Kiddo Alert
          </h1>
          <p className="mt-6 text-lg leading-8 text-gray-200">We understand that the safety and well-being of your child are paramount, even when they're at school. That's why our school's kid monitoring software goes above and beyond to ensure parents are always informed about their child's arrival and departure.</p>       
        </div>
        <div className='mt-10'>
          <BtnPrimary BtnName="Sign up now" />
        </div>
      </div>

    </div>
  </div>
  )
}

export default HomeHeader